import React, { useState, useEffect } from 'react';
import encryptionImg from '../../../assets/Images/EncryptionScreen.png';
import ConfirmationBox from '../Common/ConfirmationBox/index';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { getItem, setItem } from '../../../util/localStorage';
import PropTypes from 'prop-types';
import DisclaimerDialog from './Disclaimer/index';
import { UserType } from '../../../constants';

const propTypes = {
    loadTile: PropTypes.any,
    setActiveStrips: PropTypes.any,
    setTotalStrips: PropTypes.any,
    SetStripsCls: PropTypes.any,
    setIsSecureDoc: PropTypes.any,
    resetPassword: PropTypes.any,
};

InitialPage.propTypes = propTypes;

export function InitialPage({ loadTile, setActiveStrips, setTotalStrips, SetStripsCls, setIsSecureDoc, resetPassword }) {

    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const [imageloaded, setImageloaded] = useState(false);
    const [disclaimerOpen, setDisclaimeropen] = useState(true);


    useEffect(() => {
        setActiveStrips(1);
    }, []);

    const username = getItem('userFirstName');

    function resetPasswordDirectly() {
        setItem("uType", UserType.bitLocker);
        setOpenConfirmationBox(true);
    }

    function resetPasswordWithChallengeCode() {
        setItem("uType", UserType.winMagic)
        loadTile("GetStarted");
    }

    function handleConfirmationBoxCancel() {
        setOpenConfirmationBox(false);
    }

    function handleProceed() {
        setOpenConfirmationBox(false);
        proceedNext();
    }

    function proceedNext() {
        setIsSecureDoc(false);
        SetStripsCls('strip-3')
        setTotalStrips(3);
        resetPassword();
    }

    const onLoad = () => {
        setImageloaded(true);
    };

    return (
        <div>
            <ConfirmationBox data-testid="conmodal"
                openConfirmationBox={openConfirmationBox}
                handleConfirmationBoxCancel={handleConfirmationBoxCancel}
                handleProceed={handleProceed}
                dialogContentText={"Your are proceeding to reset your password and generate a temporary password."}
            />

            <DisclaimerDialog dialogOpen={disclaimerOpen} close={() => { setDisclaimeropen(false) }}></DisclaimerDialog>
            {!disclaimerOpen &&
                <div className="bg-panel">
                    <div className="card-panel">
                        <div className="follow-instruction">
                            <div className="name-initial">Hi {username}!</div>
                            {!imageloaded ? <Skeleton width="100%">
                                <Typography>.</Typography>
                            </Skeleton> :
                                <Typography>
                                    <div>Do you see the pre-boot login screen on your Wells Fargo device? Refer image below.</div>
                                </Typography>}
                            <div className="encryption-img-container" >
                                {!imageloaded && <Skeleton height="20rem" width="80%" animation="wave" variant="rectangular" />}
                                <Box sx={{ display: !imageloaded ? 'none' : 'flex', justifyContent: 'center' }}>
                                    <img onLoad={() => onLoad()} role="presentation" alt="Pre-boot login page with forgot password heading and username/password input fields" className="encryption-img" src={encryptionImg} />
                                </Box>
                            </div>
                        </div>
                    </div>

                    <div className="btn-container" >
                        <input data-testid="No" type="submit" className="button-secondary" value="No" onClick={() => resetPasswordDirectly()} />
                        <input data-testid="Yes" type="submit" className="button-primary shift-right" value="Yes" onClick={() => resetPasswordWithChallengeCode()} />
                    </div>
                </div>
            }
        </div>
    );
}
